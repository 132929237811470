import * as React from "react"
import { graphql } from 'gatsby';

import NotFound from 'pages/404';

import Layout from 'templates/Layout';
import Content from 'templates/Content';
import FeaturedStaff from 'global/FeaturedStaff';
import ContentCards from 'global/ContentCards';
import SimpleViewForm from "global/SimpleViewForm";
import useLiveData from 'hooks/useLiveData';

const ContentPage = (props) => {
	let staff = []
	let cardCategories = [];
	const { edges } = props.data.allContentPage;
	const nodes = edges.map(({ node }) => node);
	const { statusText: providerStatusText, status: providerStatus, response: providerResponse, visibilityChange } = useLiveData({
		type: 'ContentPage',
		apiParams: {
			uri: props.location.pathname,
		},
		staticData: props.data,
	});
	let page = nodes[0];
	if (((providerResponse && providerResponse.data.length > 0) || !!page !== false) && providerStatusText === 'LOADED') {
		page = providerResponse.data[0];
	}
	if (providerResponse && visibilityChange) page = providerResponse.data[0];
	if (!!page === false && providerStatus >= 2) return <NotFound />; // We only go to a page not found after the API loads or has an error and there's no page found.
	 if (props.providerStatus < 2){// This prevents any flash of content until the API is loaded -- but if the API takes a while to respond the effect would be the page loads slower. You could also use state to reveal content with a transition
	 	return null;
	 }
	staff = page?.Staff.map(stf => stf?.object);
	cardCategories = page?.Category.map(cat => cat?.object?.id);

	return (
		<div className={`loadWrap ${providerStatus >= 2 ? 'loaded' : ''}`}>
			<Layout containerClassName="content-page-wrapper">
				{providerStatus >= 2 && (
					<>
						<Content {...page} preContent={staff.length > 0 && page.moveStaffModuleToFooter === "top" && <FeaturedStaff members={staff} position={page.moveStaffModuleToFooter} slideButtonText={page.featuredSlideText} slideButtonUrl={page.featuredSlideUrl} />} />
						{staff.length > 0 && page.moveStaffModuleToFooter === "middle" && <FeaturedStaff members={staff} position={page.moveStaffModuleToFooter} slideButtonText={page.featuredSlideText} slideButtonUrl={page.featuredSlideUrl} />}
						{cardCategories.length > 0 && <ContentCards categoryWhitelist={cardCategories} />}
						{staff.length > 0 && page.moveStaffModuleToFooter === "bottom" && <FeaturedStaff members={staff} position={page.moveStaffModuleToFooter} slideButtonText={page.featuredSlideText} slideButtonUrl={page.featuredSlideUrl} />}
						{page.formID && <SimpleViewForm formID={page.formID} />}
					</>
				)}
			</Layout>
		</div>
	)
}

export default ContentPage;

export { Head } from 'components/templates/Head';

export const query = graphql`
query ($uri: String) {
	allContentPage(
	  filter: {uri: {eq: $uri}, approved: {eq: true}, archived: {eq: false}, deleted: {eq: false}}
	) {
	  edges {
		node {
		  pageTitle
		  meta
		  content {
			main
		  }
		  headlineBody
		  headlineTitle
		  headlineImage
		  headlineType
		  headlineBgColor
		  headlineColor
		  quicklinks
		  moveStaffModuleToFooter
		  formID
		  featuredSlideText
		  featuredSlideUrl
		  Staff {
			object {
			  firstName
			  lastName
			  permalink
			  photo
			  position
			  bio
			}
		  }
		  Category {
			object {
			  id
			}
		  }
		}
	  }
	}
}
`