import React from "react"
import Slider from "react-slick";
import { Location } from '@reach/router';

import Link from "components/Link"
import Image from "components/Image";
import zparse from "helpers/zparse";
import truncate from "helpers/truncate";
import useWindowDimensions from "hooks/useWindowDimensions";

import DataProvider from 'providers/data';

const FeaturedStaffHandler = props => {

	let { members, position, slideButtonUrl, slideButtonText } = props;

	const { isMobile } = useWindowDimensions();

	if (members && members?.length === 0) return null;

	const settings = {
		arrows: false,
		dots: true,
		infinite: true,
		speed: 500,
		autoplay: true,
		autoplaySpeed: 10000,
		slidesToShow: 1,
		slidesToScroll: 1,
		centerMode: isMobile && members.length > 2,
	};

	if ((props.providerResponse?.data.length > 0 || !!members !== false) && props.providerStatusText === "LOADED") {
		members = props.providerResponse.data[0]?.Staff.map(stf => stf?.object);
	}

	return (
		<section className={`featured-staff positioned-${position}`}>
			<div className="grid-container">
				<div className="grid-x grid-margin-x small-margin-collapse align-center">
					<div className="cell small-12 medium-10">
						<Slider {...settings}>
							{members.map((member, index) => {
								return (
									<div key={`member-${index}`} className="member">
										<div className="slideInnerWrapper">
											<div className="image-holder">
												{slideButtonUrl ?
													(
													<Link to={slideButtonUrl}>
														<Image className="image" src={member.photo} alt={`${member.firstName} ${member.lastName}`} />
													</Link>
													)
													:
													<Image className="image" src={member.photo} alt={`${member.firstName} ${member.lastName}`} />
												}
											</div>
											<div className="content">
												<div className="header">Our Staff</div>
												<div className="name">{member.firstName} {member.lastName}{member.position ? `, ${member.position}` : ""}</div>
												<div className="bio">{zparse(truncate(member.bio, 120))}</div>
												{slideButtonUrl && <Link className="button" to={slideButtonUrl}>{slideButtonText ? slideButtonText : "Learn More"}</Link>}
											</div>
										</div>
									</div>
								)
							})}
						</Slider>
					</div>
				</div>
			</div>
		</section>

	)
}

const FeaturedStaff = (props) => {
	return (
		<Location>
			{(locationProps) => (
				<DataProvider
					liveRefresh={true}
					type="ContentPage"
					apiParams={{ uri: locationProps.location.pathname, _join: true }}
				>
					<FeaturedStaffHandler {...props} />
				</DataProvider>
			)}
		</Location>
	);
};

export default FeaturedStaff;