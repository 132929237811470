import React from "react";
import { useInView } from 'react-intersection-observer';

export default function FadeIn(props) {
	let { direction, className, style, threshold, triggerOnce } = props;
	if (!style) style = {};
	if (!className) className = "";
	if (!direction) direction = "fromTop";
	if (!threshold) threshold = 0;
	if (!triggerOnce) triggerOnce = true;
	const { ref, inView } = useInView({
		/* Optional options */
		threshold: threshold,
		triggerOnce: triggerOnce
	});
	return (
		<div
			style={style}
			className={`${className} fadeIn ${!!direction ? direction : ""} ${inView ? 'isVisible' : ''}`}
			ref={ref}
		>
			{props.children}
		</div>
	);
}