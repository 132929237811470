import React from "react";
import { graphql, StaticQuery } from "gatsby";

import Link from 'components/Link'

import DataProvider from "providers/data";
import FadeIn from 'components/lib/FadeIn'

const ContentCards = props => {

	const { categoryWhitelist } = props;

	return (
		<StaticQuery
			query={graphql`
			  query AllContentCardsByCategory {
				allContentCategory(sort: {fields: sort, order: ASC}) {
				  edges {
					node {
					  id
					  name
					  Card {
						object {
						  title
						  body
						  link
						  linkText
						}
					  }
					}
				  }
				}
			  }
			`}
			render={(data) => {
				const { edges } = data.allContentCategory;
				let categories = edges.map(({ node }) => node);
				if ((props.providerResponse.data.length > 0 || !!categories !== false) && props.providerStatusText === "LOADED") {
					categories = props.providerResponse.data;
				}

				if (categoryWhitelist.length > 0) {
					categories = categories.filter(category => categoryWhitelist.includes(category.id) && category.Card.length > 0);
				}

				if (categories.length === 0) return null;

				return (
					<section id="content-cards">
						{categories.map((category, index) => {
							return (
								<div key={`category-loop-${category.name}`} className="card-wrapper">
									<div className="grid-container">
										<div className="grid-x grid-padding-x">
											<div className="cell">
												<h2 className="small-text-center">{category.name}</h2>
											</div>
										</div>
										<div className="grid-x grid-padding-x">
											{category.Card.map((card, index) => {
												return (
													<FadeIn direction={index % 2 === 0 ? "fromLeft" : "fromRight"} key={`card-loop-${index}`} className={`cell small-10 medium-${category.Card.length > 2 ? 6 : 'auto'} large-${category.Card.length > 2 ? 6 : 'auto'}`}>
														{
															card.object.link ?
																<Link to={card.object.link ? card.object.link : "#"} className={`${card.object.link ? "" : "no-link"}`}>
																	<div className="card">
																		<div className="card-content">
																			<h3>{card.object.title}</h3>
																			<p>{card.object.body}</p>
																			{card.object.link && <div className="button white no-hover">{card.object.linkText ? card.object.linkText : "View"}</div>}
																		</div>
																	</div>
																</Link>
																:
																<>
																	<div className="card">
																		<div className="card-content">
																			<h3>{card.object.title}</h3>
																			<p>{card.object.body}</p>
																			{card.object.link && <div className="button white no-hover">{card.object.linkText ? card.object.linkText : "View"}</div>}
																		</div>
																	</div>
																</>
														}
													</FadeIn>
												)
											})}
										</div>
									</div>
								</div>
							)
						})}
					</section>
				)
			}}
		/>
	)
}

const getData = (props) => <DataProvider liveRefresh={true} type="ContentCategory" apiParams={{ _join: "Card", _sort: "sort", _order: "asc" }}><ContentCards {...props} /></DataProvider>
export default getData;