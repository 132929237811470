var canUseDOM = !!(
	(typeof window !== 'undefined' &&
		window.document && window.document.createElement)
);

const mayContainHTML = (str) => {
  const htmlRegex = /<[^>]+>/; // Simple regex to detect tags
  return htmlRegex.test(str);
}

const truncateStringWithFallback = (str, limit) => {
  if (!str) return null
    
  if (str.length <= limit) {
    return str;
  }

  // Find the last period within the limit
  let lastPeriodIndex = str.lastIndexOf('.', limit);

  if (lastPeriodIndex === -1) {
    return str.substring(0, limit);
  }

  return str.substring(0, lastPeriodIndex + 1);
}

const truncateStringHTML = (str, limit) => {

  if (canUseDOM) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, 'text/html');
    const body = doc.body;
  
    let truncatedContent = '';
    let remainingCharacters = limit;
  
    for (const node of body.childNodes) {
      if (node.nodeType === Node.TEXT_NODE) {
        if (node.length <= remainingCharacters) {
          truncatedContent += node.textContent;
          remainingCharacters -= node.length;
        } else {
          truncatedContent += node.textContent.substring(0, remainingCharacters);
          remainingCharacters = 0;
          break;
        }
      } else {
        const outerHTML = node.outerHTML;
        if (outerHTML.length <= remainingCharacters) {
          truncatedContent += outerHTML;
          remainingCharacters -= outerHTML.length;
        } else {
          truncatedContent += outerHTML.substring(0, remainingCharacters);
          remainingCharacters = 0;
          break;
        }
      }
    }
  
    return truncatedContent;
  }
}

const truncate = (str, limit) => {
  if (mayContainHTML(str)) {
    return truncateStringHTML(str, limit);
  } else {
    return truncateStringWithFallback(str, limit);
  }
}

export default truncate;