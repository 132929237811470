import { useEffect, useState } from "react"

var canUseDOM = !!(
	(typeof window !== 'undefined' &&
		window.document && window.document.createElement)
);

function debounce(fn, ms) {
    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}

function useWindowDimensions() {
    const [dimensions, setDimensions] = useState({
        height: 1440,
        width: 1440
    })

    useEffect(() => {
        if (canUseDOM) {
            const debouncedHandleResize = debounce(function handleResize() {
                setDimensions({
                    height: window.innerHeight,
                    width: window.innerWidth
                })
            }, 200)

            debouncedHandleResize() // debounce
    
            window.addEventListener('resize', debouncedHandleResize)
    
            return _ => {
                window.removeEventListener('resize', debouncedHandleResize)
            }
        }
    }, [dimensions.width])

    return { dimensions, isMobile: dimensions.width < 828 }
}



export default useWindowDimensions;