import React, { useRef, useEffect, useState } from 'react';
import { Location } from '@reach/router';

var canUseDOM = !!(
	(typeof window !== 'undefined' &&
		window.document && window.document.createElement)
);

const canAccessIframe = function (iframe) {
	var html = null;
	try {
		// deal with older browsers
		var doc = iframe.contentDocument || iframe.contentWindow.document;
		html = doc.body.innerHTML;
	} catch (err) {
		// do nothing
	}

	return (html !== null);
}

const SimpleViewForm = props => {
	const { formID } = props;
	const iframeRef = useRef(null);
	const [formSubmitted, setFormSubmitted] = useState(false); 

	useEffect(() => {
		if (iframeRef.current) {
			const iframe = iframeRef.current;
			iframe.onload = () => {
				// grab all style elements and stylesheets from parent document head and inject into iframe head
				if (canUseDOM && canAccessIframe(iframe)) {
					const parentHead = document.head;
					const iframeHead = iframe.contentDocument.head;
					const parentStyles = parentHead.querySelectorAll('style');
					const parentSheets = parentHead.querySelectorAll('link[rel="stylesheet"]');
					parentStyles.forEach(style => {
						iframeHead.appendChild(style.cloneNode(true));
					});
					parentSheets.forEach(sheet => {
						iframeHead.appendChild(sheet.cloneNode(true));
					});

					setTimeout(() => {
						// get height of the form element within iframe document body
						const formHeight = iframe.contentDocument.body.querySelector('form').offsetHeight;
						iframe.style.height = `${formHeight+100}px`;
					}, 200)

				} else {
					setFormSubmitted(true); return;
				}
			};
		}
	})

	return (
		<div id="simpleview-form" className='form-holder'>
			<div className="grid-container">
				<div className="grid-x grid-margin-x align-center">
					<div className="cell small-12 medium-11">
						{!formSubmitted && <iframe
							title={"SimpleView Embedded Form"}
							style={{ width: '100%', height: '100vh', border: 'none' }}
							src={`${process.env.GATSBY_ZMS_API_URL}simpleview/form/get?formID=${formID}`}
							ref={iframeRef}
						/>}
						{formSubmitted && <div className="form-submitted">
							<h2>Thank you for your submission!</h2>
							<p>Your form has been submitted successfully.</p>
						</div>}
					</div>
				</div>
			</div>
		</div>
	);
}

const SimpleViewFormComponent = props => {
	return (
		<Location>
			{locationProps => <SimpleViewForm {...props} location={locationProps.location} />}
		</Location>
	)
}

export default SimpleViewFormComponent;
